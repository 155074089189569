import React from 'react'
import { AnchorLink, PageLayout, Section, List } from '../../components'

const ResearchProgram = ({ location }) => {
  return (
    <PageLayout 
      location={location}
      title="Research Program"
      description="Helping analyst easily build, manage, and scale data sets from any source – faster than ever before."
      pageTheme="dark"
      backgroundImages={[
        {
          type: "background-right",
          randomizeColors: true,
          color: "aliceblue",
          style: {
            position: "absolute",
            width: "60%",
            top: "60px",
            right: 0,
          }
        }
      ]}
      pageHero={{
        section: { 
          margin: { top: "4vh", bottom: "10vh", horizontal: "11vw" },
        },
        main: {
          heading: {
            label: "Research at Hippobyte.",
          },
          subHeading: {
            label: "We are looking for people like you to help us simplify enterprise data orchestration to create lovable experiences!"
          },
          style: { flex: "0 1 100%" }
        }
      }}
    >
      <Section 
        background="black"
        pad={{
          horizontal: "11vw",
          vertical: "6em"
        }}
        margin={{
          bottom: "xlarge"
        }}
        title={{
          label: "Join our Research Program"
        }}
        description={{
          label: "To join our Research Program, sign-up, we will reach out soon with additional information."
        }}
        button={{
          label: "Join Today",
          href: "https://forms.gle/AGy6XFp9TfgAbFDm8",
          color: "white",
          weight: 700
        }}
      />
      <Section
        pad={{
          horizontal: "11vw",
          bottom: "xlarge"
        }}
      >
        <List
          header={{
            label: "Frequently Asked Questions"
          }}
          data={[
            { title: "What’s involved?", description: "From time to time, we’ll invite you to participate in research. You might be asked to share your experience with one of our products, tell us about what you do, test new designs, or complete a survey. We’ll give you all the details, so you can decide each time if you want to get involved." },
            { title: "How much time will it take?", description: "The commitment for each research event varies. Often, we’ll schedule a 30-60 minute appointment. Other times, you’ll be invited to complete a survey or participate in a card sort that can be done on your own schedule. When you receive an invitation to a study, we’ll always provide you with an estimate of the time involved." },
            { title: "Do I need to be a Hippobyte user?", description: "No. We want input from the entire spectrum of people interested in cloud computing! No prior experience with cloud infrastructure in general or Hippobyte specifically is required. We will use your answers to the questions on your profile to match your experience to the tasks at hand." },
            { title: "Is there compensation?", description: "For each session you’re a part of, we’ll compensate you for your time with your choice of Hippobyte credit or a gift card." },
            { title: "Do I need to be a developer?", description: "No. Software development teams with people from all kinds of different roles use Hippobyte: developers, data scientists, QA specialists, product owners, designers, system administrators and more. We welcome your participation!" },
            { title: "After I sign up, who will have access to my information?", description: <>All your personal information will be kept confidential. It will only be accessible by the Hippobyte Research Team, consistent with our <AnchorLink path="/legal/customer-agreement">Customer Agreement</AnchorLink>.</> },
            { title: "Can I opt out?", description: <>Of course! You can decline any invitation, and if you’d like to opt-out of our research program entirely, email us at <a href="mailto:support@hippobyte.com">support@hippobyte.com</a>.</> }
          ]}
        />        
      </Section>
    </PageLayout>
  )
}

export default ResearchProgram